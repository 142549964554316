<script>
// This is the same as ArticleTitleDisplayCell.vue, but used on the Articles Grid to show the Edit button
export default {
  name: 'ArticleTitleInfoDisplayCell',
  data: function () {
    return {
      renderValue: '',
      articleData: false,
      buttonVisible: false
    };
  },
  beforeMount() {
    this.renderValue = this.params.value;
  },
  mounted() {
    if (this.params.data) {
      this.articleData = this.params.data
    }
  },
  methods: {
  }
};
</script>

<template>
  <div class="articleDisplay">
    <RouterLink :to="{path: '/articles/' + this.articleData.latina_article_id}"><span class="articleDisplay__text">{{ renderValue }}</span></RouterLink>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {
    background: transparent;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #000;
    }

    svg {
      fill: #000;
      width: 16px;
    }

    &:hover {
      i {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }
}
</style>