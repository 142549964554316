<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18 title-container">
            <span>Articles</span>
          </h4>
          <div class="page-title-right">
            <span class="rowCount">{{ this.totalRows.toLocaleString() }} Articles found</span>
            <span class="supplierFilter tag"
                  v-if="filteredSupplier"><strong>Supplier: </strong>{{ filteredSupplier.name }}  <b-button
                variant="outline-primary" size="sm" @click="resetSupplierFilter"><i
                class="bx bx-x"></i></b-button></span>
            <ol class="breadcrumb m-0">
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <ArticlesGrid/>
      </div>
    </div>
    <UploadArticlesModal/>
    <AddArticlePriceModal/>
    <UploadPurchaseStatisticsModal/>
    <ArticlesFiltersModal/>
    <DeactivateArticleModal />
    <EditArticleModal />
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import ArticlesGrid from "../components/articles/ArticlesGrid";
import {mapState} from "vuex";
import UploadArticlesModal from "../components/articles/UploadArticlesModal";
import AddArticlePriceModal from "../components/articles/AddArticlePriceModal";
import UploadPurchaseStatisticsModal from "../components/articles/UploadPurchaseStatisticsModal";
import ArticlesFiltersModal from "../components/articles/ArticlesFiltersModal";
import DeactivateArticleModal from "../components/articles/DeactivateArticleModal";
import EditArticleModal from "../components/articles/EditArticleModal.vue";

export default {
  name: 'Articles',
  components: {
    EditArticleModal,
    ArticlesGrid,
    ArticlesFiltersModal,
    DeactivateArticleModal,
    UploadArticlesModal,
    AddArticlePriceModal,
    UploadPurchaseStatisticsModal,
    Layout
  },
  created() {
    this.$store.dispatch('ancillaries/fetchSuppliers');
  },
  computed: mapState({
    totalRows: (state) => state.articles.articleLastRow,
    filteredSupplier: (state) => state.articles.filterSupplier
  }),
  methods: {
    openArticleUploadModal() {
      this.$store.dispatch('articles/openAddModal');
    },
    openStatisticsUploadModal() {
      this.$store.dispatch('articles/openStatisticsUploadModal');
    },
    toggleFiltersModal() {
      this.$store.dispatch('articles/openFiltersModal');
    },
    resetSupplierFilter() {
      this.$store.dispatch('articles/clearSupplierFilter');
    }
  }
}
</script>
